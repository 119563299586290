.main {
	min-height: auto;
	max-width: 70vw;
	padding: 40px;
	margin: auto;
	text-align: center;
}

.body-2 {
	max-width: 80%;
	overflow-x: auto;
	margin: 50px auto;
}

.date-created {
	font-size: 20px;
	font-weight: 600;
}

.share-post {
	display: flex;
	justify-content: flex-end;
	max-width: 50vw;
	margin: 0px auto 80px auto;
	gap: 5px;
}

.posts {
	min-height: 40vh;
	max-width: 50vw;
	margin: 40px auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 20px;
}

.post {
	border: 2px solid var(--color-primary-dark);
	border-radius: 12px;
	width: 305px;
	height: 390px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	padding: 0px;
	margin: auto;
}

.blog-image {
	margin: auto;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	transition: all 0.15s ease-out;
	object-fit: cover;
	height: 183px;
	width: 100%;
}

.blog-image-section {
	height: 50%;
}

.blog-details {
	height: 50%;
	margin: 10px;
}

.date-published {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
}

.title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 15px;
}

.body {
	font-size: 15px;
}

.image > img {
	height: 700px;
	width: 100%;
	object-fit: cover;
}

.blog-img {
	height: 700px;
	object-fit: cover;
	width: 1200px;
}

@media screen and (max-width: 640px) {
	.main {
		max-width: 100vw;
		padding: 40px 5px;
	}

	.body-2 {
		max-width: 100%;
	}

	.posts {
		max-width: 100vw;
		flex-flow: row wrap;
	}

	.post {
		width: 100vw;
		margin: auto 25px;
	}

	.flex-wrap-custom {
		flex-wrap: wrap;
	}
}

@media screen and (min-width: 640px) and (max-width: 768px) {
	.main {
		max-width: 100vw;
		padding: auto;
	}

	.posts {
		max-width: 100vw;
	}

	.flex-wrap-custom {
		flex-wrap: wrap;
	}
}

@media screen and (min-width: 768px) and (max-width: 840px) {
	.main {
		max-width: 100vw;
		padding: auto;
	}

	.posts {
		max-width: 100vw;
	}

	.flex-wrap-custom {
		flex-wrap: wrap;
	}
}

@media screen and (min-width: 840px) and (max-width: 1023px) {
	.main {
		max-width: 100vw;
		padding: auto;
	}

	.posts {
		max-width: 100vw;
	}

	.flex-wrap-custom {
		flex-wrap: wrap;
	}
}
